import axios from 'axios'
import config from '../../../conf.json'
import firebaseApp from '@/plugins/firebase'
import { signInWithEmailAndPassword, getAuth, setPersistence, browserSessionPersistence } from 'firebase/auth'
const defineAbilitiesFor = require('@/plugins/ability.js')
import { Claim } from '@/models/sub-models/role/claim'

const auth = {
	namespaced: true,
	state: {
		role: null,
		user: null,
		ability: defineAbilitiesFor(new Claim({})),
	},
	mutations: {
		setRole(state, role) {
			console.log('Setting user roles')
			state.role = role
		},
		setUser(state, payload) {
			console.log('Setting user')
			state.user = payload
		},
		async setAbilityRules(state, { role, uid }) {
			console.log('Setting ability')
			var ability = defineAbilitiesFor(role, uid)
			state.ability.update(ability.rules)
		},
		async unsetAbilityRules(state) {
			console.log('unsetting ability')
			var role = new Claim({})
			var ability = defineAbilitiesFor(role)
			state.ability.update(ability.rules)
		},
		clearState(state) {
			state.role = null
			state.user = null
		},
	},
	actions: {
		async signOutAction({ state, commit, dispatch }) {
			dispatch('companyVuex/clearState', null, { root: true })
			dispatch('supplierVuex/clearState', null, { root: true })
			dispatch('employeeVuex/clearState', null, { root: true })
			dispatch('userVuex/clearState', null, { root: true })
			dispatch('user/clearState', null, { root: true })
			dispatch('supplier/clearState', null, { root: true })
			dispatch('company/clearState', null, { root: true })
			commit('clearState', null)
			commit('unsetAbilityRules')
			var auth = getAuth(firebaseApp)
			var result = auth.signOut()
			return result
		},
		async signInAction({ commit }, { email, password }) {
			const auth = getAuth()
			return signInWithEmailAndPassword(auth, email, password)
		},
		async signUpAction({ commit }, { userData, password }) {
			var payload = {
				userData: userData,
				password: password,
			}
			let url = config.firebaseConfig.apiURL + '/user/register'
			return await axios(url, {
				method: 'POST',
				data: payload,
			})
		},
		async setUserAction({ commit, dispatch }, user) {
			commit('setUser', user)
			if (user) {
				var idToken = await user.getIdTokenResult()
				var role = new Claim(idToken.claims.role)
				var uid = user.uid
				commit('setAbilityRules', { role, uid })
				commit('setRole', role)
			}
		},
	},
	getters: {
		ability(state) {
			return state.ability
		},
		user(state) {
			return state.user
		},
		roles(state) {
			return state.role
		},
		isAdmin(state) {
			if (!state.role) return false
			return state.role.isAdmin
		},
		isEmployee(state) {
			if (!state.role) return false
			return state.role.isEmployee
		},
		isCompanyAdmin(state) {
			if (!state.role) return false
			return state.role.isCompanyAdmin
		},
		isAdvisor(state) {
			if (!state.role) return false
			return state.role.isAdvisor
		},
		companyAdminCompanies(state) {
			if (!state.role) return false
			return state.role.companyAdminCompanies
		},
		employeeCompanies(state) {
			if (!state.role) return false
			return state.role.employeeCompanies
		},
		uid(state) {
			return state.user.uid
		},
		async idToken(state) {
			return await state.user.getIdToken(true)
		},
	},
}

export default auth

import Vue from "vue";
import Vuex from "vuex";

// Importing modules
import authModule from "./modules/auth.js";
import supplierModule from "./modules/supplier.js";
import supplierVuex from "../modules/general/supplier/vuex/supplierVuex";
import userModule from "./modules/user.js";
import userVuex from "../modules/general/user/vuex/userVuex";
import employeeVuex from "../modules/general/employee/vuex/employeeVuex";
import appModule from "./modules/app.js";
import companyVuexModule from "../modules/general/company/vuex/companyVuex";
import companyModule from "./modules/company.js";

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        auth: authModule,
        supplier: supplierModule,
        supplierVuex: supplierVuex,
        user: userModule,
        userVuex: userVuex,
        employeeVuex: employeeVuex,
        app: appModule,
        companyVuex: companyVuexModule,
        company: companyModule,
        // other modules here ...
    },
});
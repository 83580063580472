import i18n from '@/plugins/i18n'

// Employee in company
const EmployeeInfo = () => import('@/modules/general/employee/views/EmployeeInfo')
const EmployeeAdvisor = () => import(/* webpackChunkName: 'EmployeeAdvisor' */ '@/modules/general/employee/views/EmployeeAdvisor')
const EmployeeCondition = () => import('@/modules/general/employee/views/EmployeeCondition')
const EmployeeAbsence = () => import('@/modules/general/employee/views/EmployeeAbsence')
const EmployeeSickLeave = () => import('@/modules/general/employee/views/EmployeeSickLeave')

const EmployeeResignation = () => import('@/modules/general/employee/views/EmployeeResignation')
const EmployeePrivate = () => import('@/modules/general/employee/views/EmployeePrivate')
const EmployeeIndividual = () => import('@/modules/general/employee/views/EmployeeIndividual')
const EmployeePension = () => import('@/modules/general/employee/views/EmployeePension')
const EmployeePensionList = () => import('@/modules/general/employee/views/EmployeePensionList')

const EmployeeInsurance = () => import('@/modules/general/employee/views/EmployeeInsurance')
const EmployeeInsuranceList = () => import('@/modules/general/employee/views/EmployeeInsuranceList')

const TransferAdd = () => import('@/modules/general/employee/views/TransferAdd')
const TransferEdit = () => import('@/modules/general/employee/views/TransferEdit')

const CustodianAdd = () => import('@/modules/general/employee/views/CustodianAdd')
const CustodianEdit = () => import('@/modules/general/employee/views/CustodianEdit')

const EmployeeCoverageOverview = () => import('@/modules/general/employee/views/EmployeeCoverageOverview')
const EmployeeSettings = () => import('@/modules/general/employee/views/EmployeeSettings')
const EmployeeLogs = () => import('@/modules/general/employee/views/EmployeeLogs')
const BaseEmployee = () => import(/* webpackChunkName: 'BaseEmployee' */ '@/modules/general/employee/views/BaseEmployee')

export default [
	{
		path: '/company/:companyId/employee/:employeeId/policyId/:policyId',
		name: 'BaseEmployee',
		component: BaseEmployee,
		children: [
			{
				path: 'info',
				name: 'CompanyEmployeeInfo',
				component: EmployeeInfo,
				meta: {
					action: 'read',
					resource: 'User',
					requiresLoggedIn: true,
					title: i18n.t('browserTitle.employee.info') + ' | ' + i18n.t('browserTitle.employees.update'),
				},
			},
			{
				path: 'advisor',
				name: 'CompanyEmployeeAdvisor',
				component: EmployeeAdvisor,
				meta: {
					action: 'read',
					resource: 'EmployeePolicy',
					params: 'CompanyAdvisors',
					requiresLoggedIn: true,
					title: i18n.t('browserTitle.employee.advisor') + ' | ' + i18n.t('browserTitle.employees.update'),
				},
			},
			{
				path: 'condition',
				name: 'CompanyEmployeeCondition',
				component: EmployeeCondition,
				meta: {
					action: 'read',
					resource: 'EmployeePolicy',
					params: 'EmployeeCondition',
					requiresLoggedIn: true,
					title: i18n.t('browserTitle.employee.condition') + ' | ' + i18n.t('browserTitle.employees.update'),
				},
			},
			{
				path: 'absence',
				name: 'CompanyEmployeeAbsence',
				component: EmployeeAbsence,
				meta: {
					action: 'read',
					resource: 'EmployeePolicy',
					params: 'Absence',
					requiresLoggedIn: true,
					title: 'Orlov' + ' | ' + i18n.t('browserTitle.employees.update'),
				},
			},
			{
				path: 'sickleave',
				name: 'CompanyEmployeeSickLeave',
				component: EmployeeSickLeave,
				meta: {
					action: 'read',
					resource: 'EmployeePolicy',
					params: 'SickLeave',
					requiresLoggedIn: true,
					title: 'Sygdom' + ' | ' + i18n.t('browserTitle.employees.update'),
				},
			},
			{
				path: 'resignation',
				name: 'CompanyEmployeeResignation',
				component: EmployeeResignation,
				meta: {
					action: 'update',
					resource: 'EmployeePolicy',
					params: 'EmployeeCondition',
					requiresLoggedIn: true,
					title: i18n.t('browserTitle.employee.resignation') + ' | ' + i18n.t('browserTitle.employees.update'),
				},
			},
			{
				path: 'individual',
				name: 'CompanyEmployeeIndividual',
				component: EmployeeIndividual,
				meta: {
					action: 'read',
					resource: 'EmployeePolicy',
					params: 'IndividualCondition',
					requiresLoggedIn: true,
					title: i18n.t('browserTitle.employee.individual') + ' | ' + i18n.t('browserTitle.employees.update'),
				},
			},
			{
				path: 'transfer/add',
				name: 'TransferAdd',
				component: TransferAdd,
				meta: {
					action: 'read',
					resource: 'EmployeePolicy',
					params: 'IndividualCondition',
					requiresLoggedIn: true,
					title: i18n.t('browserTitle.employee.individual') + ' | ' + i18n.t('browserTitle.employees.update'),
				},
			},
			{
				path: 'transfer/:index/edit',
				name: 'TransferEdit',
				component: TransferEdit,
				meta: {
					action: 'read',
					resource: 'EmployeePolicy',
					params: 'IndividualCondition',
					requiresLoggedIn: true,
					title: i18n.t('browserTitle.employee.individual') + ' | ' + i18n.t('browserTitle.employees.update'),
				},
			},
			{
				path: 'custodian/add',
				name: 'CustodianAdd',
				component: CustodianAdd,
				meta: {
					action: 'read',
					resource: 'EmployeePolicy',
					params: 'IndividualCondition',
					requiresLoggedIn: true,
					title: i18n.t('browserTitle.employee.individual') + ' | ' + i18n.t('browserTitle.employees.update'),
				},
			},
			{
				path: 'custodian/:index/edit',
				name: 'CustodianEdit',
				component: CustodianEdit,
				meta: {
					action: 'read',
					resource: 'EmployeePolicy',
					params: 'IndividualCondition',
					requiresLoggedIn: true,
					title: i18n.t('browserTitle.employee.individual') + ' | ' + i18n.t('browserTitle.employees.update'),
				},
			},

			{
				path: 'private',
				name: 'CompanyEmployeePrivate',
				component: EmployeePrivate,
				meta: {
					action: 'read',
					resource: 'EmployeePolicy',
					params: 'PrivateCondition',
					requiresLoggedIn: true,
					title: i18n.t('browserTitle.employee.private') + ' | ' + i18n.t('browserTitle.employees.update'),
				},
			},
			{
				path: 'coverage',
				name: 'CompanyEmployeeCoverageOverview',
				component: EmployeeCoverageOverview,
				meta: {
					action: 'read',
					resource: 'CoverageOverview',
					requiresLoggedIn: true,
					title: i18n.t('browserTitle.employee.coverage') + ' | ' + i18n.t('browserTitle.employees.update'),
				},
			},
			{
				path: 'pension',
				name: 'CompanyEmployeePensionList',
				component: EmployeePensionList,
				meta: {
					action: 'read',
					resource: 'EmployeePolicy',
					params: 'EmployeePensions',
					requiresLoggedIn: true,
					title: i18n.t('browserTitle.employee.pension') + ' | ' + i18n.t('browserTitle.employees.update'),
				},
			},
			{
				path: 'pension/:pensionId',
				name: 'CompanyEmployeePension',
				component: EmployeePension,
				meta: {
					action: 'read',
					resource: 'EmployeePolicy',
					params: 'EmployeePensions',
					requiresLoggedIn: true,
					title: i18n.t('browserTitle.employee.pension') + ' | ' + i18n.t('browserTitle.employees.update'),
				},
			},

			{
				path: 'insurance',
				name: 'CompanyEmployeeInsuranceList',
				component: EmployeeInsuranceList,
				meta: {
					action: 'read',
					resource: 'EmployeePolicy',
					params: 'Insurances',
					requiresLoggedIn: true,
					title: i18n.t('browserTitle.employee.insurance') + ' | ' + i18n.t('browserTitle.employees.update'),
				},
			},
			{
				path: 'insurance/:insuranceId',
				name: 'CompanyEmployeeInsurance',
				component: EmployeeInsurance,
				meta: {
					action: 'read',
					resource: 'EmployeePolicy',
					params: 'Insurances',
					requiresLoggedIn: true,
					title: i18n.t('browserTitle.employee.insurance') + ' | ' + i18n.t('browserTitle.employees.update'),
				},
			},
			{
				path: 'settings',
				name: 'CompanyEmployeeSettings',
				component: EmployeeSettings,
				meta: {
					requiresLoggedIn: true,
					title: i18n.t('browserTitle.employee.settings'),
				},
			},
			{
				path: 'logs',
				name: 'CompanyEmployeeLogs',
				component: EmployeeLogs,
				meta: {
					requiresLoggedIn: true,
					title: i18n.t('browserTitle.employee.logs'),
				},
			},
		],
	},
]

"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.CoverageOverview = void 0;
const class_validator_1 = require("class-validator");
const employeePolicy_1 = require("../../employeePolicy");
const company_1 = require("../../company");
class CoverageOverview {
    constructor(fields) {
        this.employeePolicy = new employeePolicy_1.EmployeePolicy({});
        this.company = new company_1.Company({});
        for (let [key, value] of Object.entries(fields)) {
            switch (key) {
                case 'employeePolicy':
                    this[key] = new employeePolicy_1.EmployeePolicy(value);
                    break;
                case 'company':
                    this[key] = new company_1.Company(value);
                    break;
                default:
                    this[key] = value;
                    break;
            }
        }
    }
    getStandardPension(pensionId) {
        return this.company.pensions.find((pension) => pension.id === pensionId);
    }
    getStandardInsurance(insuranceId) {
        return this.company.insurances.find((insurance) => insurance.id === insuranceId);
    }
    getPensions() {
        var arr = this.getCompanyPension();
        var arr2 = this.getEmployeePension();
        var arr3 = arr.concat(arr2);
        return arr3;
    }
    getEmployeePension() {
        var arr = [];
        var employeePensions = this.employeePolicy.pensions;
        var employeeCondition = this.employeePolicy.employeeCondition;
        employeePensions.forEach((pension) => {
            let salary = employeeCondition.salary;
            let supplementarySalary = 0;
            const totalSalary = salary + supplementarySalary;
            var standardPension = this.getStandardPension(pension.pensionId).pension;
            if (pension.voluntaryCompanyContribution.value) {
                var item = {};
                item['valuePercentage'] = pension.voluntaryCompanyContribution.value;
                item['valueMonth'] = (pension.voluntaryCompanyContribution.value * totalSalary) / 100;
                item['valueYear'] = ((pension.voluntaryCompanyContribution.value * totalSalary) / 100) * 12;
                item['title'] = pension.voluntaryCompanyContribution.title;
                item['supplier'] = standardPension.supplierId;
                item['comment'] = pension.voluntaryCompanyContribution.comment;
                arr.push(item);
            }
            if (pension.voluntaryEmployeeContribution.value) {
                var item = {};
                item['valuePercentage'] = pension.voluntaryEmployeeContribution.value;
                item['valueMonth'] = (pension.voluntaryEmployeeContribution.value * totalSalary) / 100;
                item['valueYear'] = ((pension.voluntaryEmployeeContribution.value * totalSalary) / 100) * 12;
                item['title'] = pension.voluntaryEmployeeContribution.title;
                item['comment'] = pension.voluntaryEmployeeContribution.comment;
                item['supplier'] = standardPension.supplierId;
                arr.push(item);
            }
            if (pension.employeeDeposit.value) {
                var item = {};
                item['valuePercentage'] = (pension.employeeDeposit.value / totalSalary) * 100;
                item['valueMonth'] = pension.employeeDeposit.value;
                item['valueYear'] = pension.employeeDeposit.value * 12;
                item['title'] = pension.employeeDeposit.title;
                item['supplier'] = standardPension.supplierId;
                arr.push(item);
            }
        });
        return arr;
    }
    getCompanyPension() {
        var arr = [];
        var employeeCondition = this.employeePolicy.employeeCondition;
        var companyPensions = this.company.pensions;
        var groupId = employeeCondition.group;
        var filteredCompanyPensions = companyPensions.filter((companyPension) => companyPension.groups.includes(groupId));
        filteredCompanyPensions.forEach((companyPension) => {
            var standardPension = companyPension.pension;
            let salary = employeeCondition.salary;
            let supplementarySalary = 0;
            if (standardPension.isBeneficial.value) {
                supplementarySalary = employeeCondition.supplementarySalary;
            }
            const totalSalary = salary + supplementarySalary;
            if (standardPension.mandatoryCompanyShare.value) {
                let item = {};
                item['valuePercentage'] = standardPension.mandatoryCompanyShare.value;
                item['valueMonth'] = (standardPension.mandatoryCompanyShare.value * totalSalary) / 100;
                item['valueYear'] = ((standardPension.mandatoryCompanyShare.value * totalSalary) / 100) * 12;
                item['title'] = standardPension.mandatoryCompanyShare.title;
                item['tax'] = standardPension.mandatoryCompanyShare.taxCode;
                item['comment'] = standardPension.mandatoryCompanyShare.comment;
                item['supplier'] = standardPension.supplierId;
                arr.push(item);
            }
            if (standardPension.mandatoryEmployeeShare.value) {
                let item = {};
                item['valuePercentage'] = standardPension.mandatoryEmployeeShare.value;
                item['valueMonth'] = (standardPension.mandatoryEmployeeShare.value * totalSalary) / 100;
                item['valueYear'] = ((standardPension.mandatoryEmployeeShare.value * totalSalary) / 100) * 12;
                item['title'] = standardPension.mandatoryEmployeeShare.title;
                item['tax'] = standardPension.mandatoryEmployeeShare.taxCode;
                item['comment'] = standardPension.mandatoryEmployeeShare.comment;
                item['supplier'] = standardPension.supplierId;
                arr.push(item);
            }
        });
        return arr;
    }
    getDeathInsurance() {
        var arr = [];
        var employeeCondition = this.employeePolicy.employeeCondition;
        this.employeePolicy.insurances.forEach((insurance) => {
            var standardInsurance = this.getStandardInsurance(insurance.insuranceId).insurance;
            let salary = employeeCondition.salary;
            let supplementarySalary = 0;
            if (standardInsurance.supplementarySalary.value) {
                supplementarySalary = employeeCondition.supplementarySalary;
            }
            const totalSalary = salary + supplementarySalary;
            if (insurance.deathSum.value) {
                var item = {};
                item['coverageType'] = 'Valgt dækning';
                //
                var taxCode = insurance.deathSum.taxCode;
                if (taxCode === '') {
                    taxCode = standardInsurance.deathSum.taxCode;
                }
                if (insurance.deathSum.type == 'currency') {
                    // item["value"] = insurance.deathSum.value
                    item['valueOneTimePayment'] = insurance.deathSum.value * 12;
                    item['title'] = insurance.deathSum.title;
                    item['comment'] = insurance.deathSum.comment;
                    item['tax'] = taxCode;
                    item['type'] = insurance.deathSum.type;
                    item['supplier'] = standardInsurance.supplierId;
                    arr.push(item);
                }
                else if (insurance.deathSum.type == 'percentage') {
                    item['valueAnnualPercentage'] = insurance.deathSum.value;
                    item['valueOneTimePayment'] = ((insurance.deathSum.value * totalSalary) / 100) * 12;
                    item['title'] = insurance.deathSum.title;
                    item['tax'] = taxCode;
                    item['type'] = insurance.deathSum.type;
                    item['comment'] = insurance.deathSum.comment;
                    item['tax'] = insurance.deathSum.taxCode;
                    item['supplier'] = standardInsurance.supplierId;
                    arr.push(item);
                }
            }
            else if (standardInsurance.deathSum.value) {
                var item = {};
                item['coverageType'] = 'Standard dækning';
                var taxCode = standardInsurance.deathSum.taxCode;
                if (standardInsurance.deathSum.type == 'currency') {
                    // item["value"] = insurance.deathSum.value
                    item['valueOneTimePayment'] = standardInsurance.deathSum.value * 12;
                    item['title'] = standardInsurance.deathSum.title;
                    item['comment'] = standardInsurance.deathSum.comment;
                    item['tax'] = taxCode;
                    item['type'] = standardInsurance.deathSum.type;
                    item['supplier'] = standardInsurance.supplierId;
                    arr.push(item);
                }
                else if (standardInsurance.deathSum.type == 'percentage') {
                    item['valueAnnualPercentage'] = standardInsurance.deathSum.value;
                    item['valueOneTimePayment'] = ((standardInsurance.deathSum.value * totalSalary) / 100) * 12;
                    item['title'] = standardInsurance.deathSum.title;
                    item['tax'] = taxCode;
                    item['type'] = standardInsurance.deathSum.type;
                    item['comment'] = standardInsurance.deathSum.comment;
                    item['supplier'] = standardInsurance.supplierId;
                    arr.push(item);
                }
            }
            if (insurance.childPension.value) {
                var item = {};
                item['coverageType'] = 'Valgt dækning';
                var taxCode = insurance.childPension.taxCode;
                if (taxCode === '') {
                    taxCode = standardInsurance.childPension.taxCode;
                }
                if (insurance.childPension.type == 'currency') {
                    item['valueAnnualPayment'] = insurance.childPension.value;
                    item['title'] = insurance.childPension.title;
                    item['comment'] = insurance.childPension.comment;
                    item['tax'] = taxCode;
                    item['type'] = insurance.childPension.type;
                    item['supplier'] = standardInsurance.supplierId;
                    arr.push(item);
                }
                else if (insurance.childPension.type == 'percentage') {
                    item['valueAnnualPercentage'] = insurance.childPension.value;
                    item['valueAnnualPayment'] = (insurance.childPension.value / 100) * totalSalary * 12;
                    item['title'] = insurance.childPension.title;
                    item['tax'] = taxCode;
                    item['type'] = insurance.childPension.type;
                    item['comment'] = insurance.childPension.comment;
                    item['supplier'] = standardInsurance.supplierId;
                    arr.push(item);
                }
            }
            else if (standardInsurance.childPension.value) {
                var item = {};
                item['coverageType'] = 'Standard dækning';
                var taxCode = standardInsurance.childPension.taxCode;
                if (standardInsurance.childPension.type == 'currency') {
                    item['valueAnnualPayment'] = standardInsurance.childPension.value;
                    item['title'] = standardInsurance.childPension.title;
                    item['comment'] = standardInsurance.childPension.comment;
                    item['tax'] = taxCode;
                    item['type'] = standardInsurance.childPension.type;
                    item['supplier'] = standardInsurance.supplierId;
                    arr.push(item);
                }
                else if (standardInsurance.childPension.type == 'percentage') {
                    item['valueAnnualPercentage'] = standardInsurance.childPension.value;
                    item['valueAnnualPayment'] = (standardInsurance.childPension.value / 100) * totalSalary * 12;
                    item['title'] = standardInsurance.childPension.title;
                    item['tax'] = taxCode;
                    item['type'] = standardInsurance.childPension.type;
                    item['comment'] = standardInsurance.childPension.comment;
                    item['supplier'] = standardInsurance.supplierId;
                    arr.push(item);
                }
            }
        });
        return arr;
    }
    getDiseaseInsurance() {
        var arr = [];
        var employeeCondition = this.employeePolicy.employeeCondition;
        this.employeePolicy.insurances.forEach((insurance) => {
            var standardInsurance = this.getStandardInsurance(insurance.insuranceId).insurance;
            let salary = employeeCondition.salary;
            let supplementarySalary = 0;
            if (standardInsurance.supplementarySalary.value) {
                supplementarySalary = employeeCondition.supplementarySalary;
            }
            const totalSalary = salary + supplementarySalary;
            if (insurance.criticalDisease.value) {
                var taxCode = insurance.criticalDisease.taxCode;
                if (taxCode === '') {
                    taxCode = standardInsurance.criticalDisease.taxCode;
                }
                var item = {};
                item['coverageType'] = 'Valgt dækning';
                if (insurance.criticalDisease.type == 'currency') {
                    item['valueOneTimePayment'] = insurance.criticalDisease.value;
                    item['title'] = insurance.criticalDisease.title;
                    item['type'] = insurance.criticalDisease.type;
                    item['comment'] = insurance.criticalDisease.comment;
                    item['tax'] = taxCode;
                    item['supplier'] = standardInsurance.supplierId;
                    arr.push(item);
                }
                if (insurance.criticalDisease.type == 'percentage') {
                    item['valueAnnualPercentage'] = insurance.criticalDisease.value;
                    item['valueOneTimePayment'] = (insurance.criticalDisease.value / 100) * totalSalary * 12;
                    item['title'] = insurance.criticalDisease.title;
                    item['tax'] = taxCode;
                    item['type'] = insurance.criticalDisease.type;
                    item['comment'] = insurance.criticalDisease.comment;
                    item['supplier'] = standardInsurance.supplierId;
                    arr.push(item);
                }
            }
            else if (standardInsurance.criticalDisease.value) {
                var item = {};
                item['coverageType'] = 'Standard dækning';
                var taxCode = standardInsurance.criticalDisease.taxCode;
                if (standardInsurance.criticalDisease.type == 'currency') {
                    item['valueOneTimePayment'] = standardInsurance.criticalDisease.value;
                    item['title'] = standardInsurance.criticalDisease.title;
                    item['type'] = standardInsurance.criticalDisease.type;
                    item['comment'] = standardInsurance.criticalDisease.comment;
                    item['tax'] = taxCode;
                    item['supplier'] = standardInsurance.supplierId;
                    arr.push(item);
                }
                if (standardInsurance.criticalDisease.type == 'percentage') {
                    item['valueAnnualPercentage'] = standardInsurance.criticalDisease.value;
                    item['valueOneTimePayment'] = (standardInsurance.criticalDisease.value / 100) * totalSalary * 12;
                    item['title'] = standardInsurance.criticalDisease.title;
                    item['tax'] = taxCode;
                    item['type'] = standardInsurance.criticalDisease.type;
                    item['comment'] = standardInsurance.criticalDisease.comment;
                    item['supplier'] = standardInsurance.supplierId;
                    arr.push(item);
                }
            }
            if (insurance.criticalDiseaseChild.value) {
                var item = {};
                item['coverageType'] = 'Valgt dækning';
                var taxCode = insurance.criticalDiseaseChild.taxCode;
                if (taxCode === '') {
                    taxCode = standardInsurance.criticalDiseaseChild.taxCode;
                }
                if (insurance.criticalDiseaseChild.type == 'currency') {
                    item['valueOneTimePayment'] = insurance.criticalDiseaseChild.value;
                    item['title'] = insurance.criticalDiseaseChild.title;
                    item['type'] = insurance.criticalDiseaseChild.type;
                    item['comment'] = insurance.criticalDiseaseChild.comment;
                    item['tax'] = taxCode;
                    item['supplier'] = standardInsurance.supplierId;
                    arr.push(item);
                }
                if (insurance.criticalDiseaseChild.type == 'percentage') {
                    item['valueAnnualPercentage'] = insurance.criticalDiseaseChild.value;
                    item['valueOneTimePayment'] = (insurance.criticalDiseaseChild.value / 100) * totalSalary * 12;
                    item['title'] = insurance.criticalDiseaseChild.title;
                    item['type'] = insurance.criticalDiseaseChild.type;
                    item['comment'] = insurance.criticalDiseaseChild.comment;
                    item['tax'] = taxCode;
                    item['supplier'] = standardInsurance.supplierId;
                    arr.push(item);
                }
            }
            else if (standardInsurance.criticalDiseaseChild.value) {
                var item = {};
                item['coverageType'] = 'Standard dækning';
                var taxCode = standardInsurance.criticalDiseaseChild.taxCode;
                if (standardInsurance.criticalDiseaseChild.type == 'currency') {
                    item['valueOneTimePayment'] = standardInsurance.criticalDiseaseChild.value;
                    item['title'] = standardInsurance.criticalDiseaseChild.title;
                    item['type'] = standardInsurance.criticalDiseaseChild.type;
                    item['comment'] = standardInsurance.criticalDiseaseChild.comment;
                    item['tax'] = taxCode;
                    item['supplier'] = standardInsurance.supplierId;
                    arr.push(item);
                }
                if (standardInsurance.criticalDiseaseChild.type == 'percentage') {
                    item['valueAnnualPercentage'] = standardInsurance.criticalDiseaseChild.value;
                    item['valueOneTimePayment'] = (standardInsurance.criticalDiseaseChild.value / 100) * totalSalary * 12;
                    item['title'] = standardInsurance.criticalDiseaseChild.title;
                    item['type'] = standardInsurance.criticalDiseaseChild.type;
                    item['comment'] = standardInsurance.criticalDiseaseChild.comment;
                    item['tax'] = taxCode;
                    item['supplier'] = standardInsurance.supplierId;
                    arr.push(item);
                }
            }
            if (insurance.workAbility.isSalaryScale) {
                var taxCode = insurance.workAbility.taxCode;
                if (taxCode === '') {
                    taxCode = standardInsurance.workAbility.taxCode;
                }
                var item = {};
                item['coverageType'] = 'Valgt dækning';
                item['valueAnnualPercentage'] = 'Lønskala';
                item['title'] = insurance.workAbility.title;
                item['type'] = insurance.workAbility.type;
                item['comment'] = insurance.workAbility.comment;
                item['tax'] = taxCode;
                item['supplier'] = standardInsurance.supplierId;
                arr.push(item);
            }
            else if (insurance.workAbility.value) {
                var item = {};
                item['coverageType'] = 'Valgt dækning';
                var taxCode = insurance.workAbility.taxCode;
                if (taxCode === '') {
                    taxCode = standardInsurance.workAbility.taxCode;
                }
                if (insurance.workAbility.type == 'currency') {
                    item['valueAnnualPayment'] = insurance.workAbility.value;
                    item['title'] = insurance.workAbility.title;
                    item['type'] = insurance.workAbility.type;
                    item['comment'] = insurance.workAbility.comment;
                    item['tax'] = taxCode;
                    item['supplier'] = standardInsurance.supplierId;
                    arr.push(item);
                }
                else if (insurance.workAbility.type == 'percentage') {
                    item['valueAnnualPercentage'] = insurance.workAbility.value;
                    item['valueAnnualPayment'] = (insurance.workAbility.value / 100) * totalSalary * 12;
                    item['title'] = insurance.workAbility.title;
                    item['type'] = insurance.workAbility.type;
                    item['comment'] = insurance.workAbility.comment;
                    item['tax'] = taxCode;
                    item['supplier'] = standardInsurance.supplierId;
                    arr.push(item);
                }
            }
            else if (standardInsurance.workAbility.value) {
                var item = {};
                item['coverageType'] = 'Standard dækning';
                var taxCode = standardInsurance.workAbility.taxCode;
                if (standardInsurance.workAbility.type == 'currency') {
                    // item["value"] = standardInsurance.workAbility.value
                    // item["valuePercentage"] = standardInsurance.workAbility.value
                    item['valueAnnualPayment'] = standardInsurance.workAbility.value;
                    item['title'] = standardInsurance.workAbility.title;
                    item['type'] = standardInsurance.workAbility.type;
                    item['comment'] = standardInsurance.workAbility.comment;
                    item['tax'] = taxCode;
                    item['supplier'] = standardInsurance.supplierId;
                    arr.push(item);
                }
                else if (standardInsurance.workAbility.type == 'percentage') {
                    // item["value"] = standardInsurance.workAbility.value
                    item['valueAnnualPercentage'] = standardInsurance.workAbility.value;
                    item['valueAnnualPayment'] = (standardInsurance.workAbility.value / 100) * totalSalary * 12;
                    item['title'] = standardInsurance.workAbility.title;
                    item['type'] = standardInsurance.workAbility.type;
                    item['comment'] = standardInsurance.workAbility.comment;
                    item['tax'] = taxCode;
                    item['supplier'] = standardInsurance.supplierId;
                    arr.push(item);
                }
            }
            else if (standardInsurance.workAbility.isSalaryScale) {
                var item = {};
                item['coverageType'] = 'Standard dækning';
                item['valueAnnualPercentage'] = 'Lønskala';
                item['title'] = standardInsurance.workAbility.title;
                item['type'] = standardInsurance.workAbility.type;
                item['comment'] = standardInsurance.workAbility.comment;
                item['tax'] = standardInsurance.workAbility.taxCode;
                item['supplier'] = standardInsurance.supplierId;
                arr.push(item);
            }
            if (insurance.invalideSum.value > 0) {
                var item = {};
                item['coverageType'] = 'Valgt dækning';
                var taxCode = insurance.invalideSum.taxCode;
                if (taxCode === '') {
                    taxCode = standardInsurance.invalideSum.taxCode;
                }
                if (insurance.invalideSum.type == 'currency') {
                    // item["value"] = insurance.invalideSum.value
                    // item["valuePercentage"] =  insurance.invalideSum.value / (totalSalary * 12) * 100
                    item['valueOneTimePayment'] = insurance.invalideSum.value;
                    item['title'] = insurance.invalideSum.title;
                    item['type'] = insurance.invalideSum.type;
                    item['comment'] = insurance.invalideSum.comment;
                    item['tax'] = taxCode;
                    item['supplier'] = standardInsurance.supplierId;
                    arr.push(item);
                }
                if (insurance.invalideSum.type == 'percentage') {
                    //item["value"] = insurance.invalideSum.value
                    item['valueAnnualPercentage'] = insurance.invalideSum.value;
                    item['valueOneTimePayment'] = (insurance.invalideSum.value / 100) * totalSalary * 12;
                    item['title'] = insurance.invalideSum.title;
                    item['type'] = insurance.invalideSum.type;
                    item['comment'] = insurance.invalideSum.comment;
                    item['tax'] = taxCode;
                    item['supplier'] = standardInsurance.supplierId;
                    arr.push(item);
                }
            }
            else if (standardInsurance.invalideSum.value > 0) {
                var item = {};
                item['coverageType'] = 'Standard dækning';
                if (standardInsurance.invalideSum.type == 'currency') {
                    // item["value"] = standardInsurance.invalideSum.value
                    // item["valuePercentage"] =  standardInsurance.invalideSum.value / (totalSalary * 12) * 100
                    item['valueOneTimePayment'] = standardInsurance.invalideSum.value;
                    item['title'] = standardInsurance.invalideSum.title;
                    item['type'] = standardInsurance.invalideSum.type;
                    item['comment'] = standardInsurance.invalideSum.comment;
                    item['tax'] = standardInsurance.invalideSum.taxCode;
                    item['supplier'] = standardInsurance.supplierId;
                    arr.push(item);
                }
                if (standardInsurance.invalideSum.type == 'percentage') {
                    //item["value"] = standardInsurance.invalideSum.value
                    item['valueAnnualPercentage'] = standardInsurance.invalideSum.value;
                    item['valueOneTimePayment'] = (standardInsurance.invalideSum.value / 100) * totalSalary * 12;
                    item['title'] = standardInsurance.invalideSum.title;
                    item['type'] = standardInsurance.invalideSum.type;
                    item['comment'] = standardInsurance.invalideSum.comment;
                    item['tax'] = standardInsurance.invalideSum.taxCode;
                    item['supplier'] = standardInsurance.supplierId;
                    arr.push(item);
                }
            }
            if (insurance.healthInsurance.insuranceType != '' && insurance.healthInsurance.insuranceType != null) {
                var item = {};
                item['coverageType'] = 'Valgt dækning';
                //item["value"] = insurance.invalideSum.value
                let insuranceType = insurance.healthInsurance.insuranceType;
                if (insuranceType == 'expanded') {
                    insuranceType = 'udvidet';
                }
                item['title'] = insurance.healthInsurance.title + ' ( ' + insuranceType + ' )';
                item['type'] = insurance.healthInsurance.insuranceType;
                item['comment'] = insurance.healthInsurance.comment;
                item['tax'] = insurance.healthInsurance.taxCode;
                item['supplier'] = standardInsurance.supplierId;
                arr.push(item);
            }
            else if (standardInsurance.healthInsurance.insuranceType != '' && standardInsurance.healthInsurance.insuranceType != null) {
                var item = {};
                item['coverageType'] = 'Standard dækning';
                //item["value"] = insurance.invalideSum.value
                let insuranceType = standardInsurance.healthInsurance.insuranceType;
                if (insuranceType == 'expanded') {
                    insuranceType = 'udvidet';
                }
                item['title'] = standardInsurance.healthInsurance.title + ' ( ' + insuranceType + ' )';
                item['type'] = standardInsurance.healthInsurance.insuranceType;
                item['comment'] = standardInsurance.healthInsurance.comment;
                item['tax'] = standardInsurance.healthInsurance.taxCode;
                item['supplier'] = standardInsurance.supplierId;
                arr.push(item);
            }
            if (insurance.healthInsuranceChild.insuranceType != '' && insurance.healthInsuranceChild.insuranceType != null) {
                var item = {};
                item['coverageType'] = 'Valgt dækning';
                //item["value"] = insurance.invalideSum.value
                let insuranceType = insurance.healthInsuranceChild.insuranceType;
                if (insuranceType == 'expanded') {
                    insuranceType = 'udvidet';
                }
                item['title'] = insurance.healthInsuranceChild.title + ' ( ' + insuranceType + ' )';
                item['type'] = insurance.healthInsuranceChild.insuranceType;
                item['comment'] = insurance.healthInsuranceChild.comment;
                item['tax'] = insurance.healthInsuranceChild.taxCode;
                item['supplier'] = standardInsurance.supplierId;
                arr.push(item);
            }
            else if (standardInsurance.healthInsuranceChild.insuranceType != '' && standardInsurance.healthInsuranceChild.insuranceType != null) {
                var item = {};
                item['coverageType'] = 'Standard dækning';
                //item["value"] = insurance.invalideSum.value
                let insuranceType = standardInsurance.healthInsuranceChild.insuranceType;
                if (insuranceType == 'expanded') {
                    insuranceType = 'udvidet';
                }
                item['title'] = standardInsurance.healthInsuranceChild.title + ' ( ' + insuranceType + ' )';
                item['type'] = standardInsurance.healthInsuranceChild.insuranceType;
                item['comment'] = standardInsurance.healthInsuranceChild.comment;
                item['tax'] = standardInsurance.healthInsuranceChild.taxCode;
                item['supplier'] = standardInsurance.supplierId;
                arr.push(item);
            }
            if (insurance.healthInsurancePartner.insuranceType != '' && insurance.healthInsurancePartner.insuranceType != null) {
                var item = {};
                item['coverageType'] = 'Valgt dækning';
                //item["value"] = insurance.invalideSum.value
                let insuranceType = insurance.healthInsurancePartner.insuranceType;
                if (insuranceType == 'expanded') {
                    insuranceType = 'udvidet';
                }
                item['title'] = insurance.healthInsurancePartner.title + ' ( ' + insuranceType + ' )';
                item['type'] = insurance.healthInsurancePartner.insuranceType;
                item['comment'] = insurance.healthInsurancePartner.comment;
                item['tax'] = insurance.healthInsurancePartner.taxCode;
                item['supplier'] = standardInsurance.supplierId;
                arr.push(item);
            }
            else if (standardInsurance.healthInsurancePartner.insuranceType != '' && standardInsurance.healthInsurancePartner.insuranceType != null) {
                var item = {};
                item['coverageType'] = 'Standard dækning';
                //item["value"] = insurance.invalideSum.value
                let insuranceType = standardInsurance.healthInsurancePartner.insuranceType;
                if (insuranceType == 'expanded') {
                    insuranceType = 'udvidet';
                }
                item['title'] = standardInsurance.healthInsurancePartner.title + ' ( ' + insuranceType + ' )';
                item['type'] = standardInsurance.healthInsurancePartner.insuranceType;
                item['comment'] = standardInsurance.healthInsurancePartner.comment;
                item['tax'] = standardInsurance.healthInsurancePartner.taxCode;
                item['supplier'] = standardInsurance.supplierId;
                arr.push(item);
            }
            if (standardInsurance.healthInsurances.length > 0) {
                var title = '';
                var comment = '';
                var item = {};
                standardInsurance.healthInsurances.forEach((healthInsurance) => {
                    if (healthInsurance.value) {
                        if (title == '') {
                            title = healthInsurance.title;
                        }
                        else {
                            title = title + ', ' + healthInsurance.title;
                        }
                        if (comment == '') {
                            comment = healthInsurance.comment;
                        }
                        else {
                            comment = comment + ', ' + healthInsurance.comment;
                        }
                        item['supplier'] = standardInsurance.supplierId;
                    }
                });
                item['title'] = title;
                item['comment'] = comment;
                if (Object.keys(item).length > 0) {
                    arr.push(item);
                }
            }
        });
        return arr;
    }
}
__decorate([
    (0, class_validator_1.ValidateNested)({ each: true })
], CoverageOverview.prototype, "employeePolicy", void 0);
__decorate([
    (0, class_validator_1.ValidateNested)({ each: true })
], CoverageOverview.prototype, "company", void 0);
exports.CoverageOverview = CoverageOverview;

"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Group = void 0;
const class_validator_1 = require("class-validator");
class Group {
    constructor(fields) {
        this.name = '';
        for (let [key, value] of Object.entries(fields)) {
            switch (key) {
                default:
                    this[key] = value;
                    break;
            }
        }
    }
    toJSONObject() {
        return {
            id: this.id,
            name: this.name,
        };
    }
    toDatabase() {
        return {
            id: this.id,
            name: this.name,
        };
    }
    async isValid() {
        return await (0, class_validator_1.validate)(this).then((errors) => {
            if (errors.length > 0) {
                return errors;
            }
            else {
                return null;
            }
        });
    }
}
__decorate([
    (0, class_validator_1.IsString)()
], Group.prototype, "name", void 0);
__decorate([
    (0, class_validator_1.IsString)()
], Group.prototype, "id", void 0);
exports.Group = Group;

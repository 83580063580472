"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.CompanyInfo = void 0;
const class_validator_1 = require("class-validator");
const address_1 = require("./address");
class CompanyInfo {
    constructor(fields) {
        this.name = "";
        this.address = new address_1.Address({});
        this.phone = "";
        this.contactPersonFullName = "";
        this.contactPersonEmail = "";
        for (let [key, value] of Object.entries(fields)) {
            switch (key) {
                case "address":
                    this[key] = new address_1.Address(value);
                    break;
                case "cvr":
                    this[key] = Number(value); // Convert to number
                    break;
                case "startDate":
                    if (value) {
                        if (value.hasOwnProperty("_seconds")) { //Parse from timestamp to date
                            this[key] = new Date(value.toDate());
                        }
                        else {
                            this[key] = new Date(value);
                        }
                    }
                    break;
                case "hovedforfald":
                    if (value) {
                        if (value.hasOwnProperty("_seconds")) { //Parse from timestamp to date
                            this[key] = new Date(value.toDate());
                        }
                        else {
                            this[key] = new Date(value);
                        }
                    }
                    break;
                default:
                    this[key] = value;
                    break;
            }
        }
    }
    toJSONObject() {
        return {
            name: this.name,
            cvr: this.cvr,
            address: this.address.toJSONObject(),
            phone: this.phone,
            contactPersonEmail: this.contactPersonEmail,
            contactPersonFullName: this.contactPersonFullName,
            startDate: this.startDate,
            hovedforfald: this.hovedforfald
        };
    }
    toDatabase() {
        return {
            name: this.name,
            cvr: this.cvr,
            address: this.address.toDatabase(),
            phone: this.phone,
            contactPersonEmail: this.contactPersonEmail,
            contactPersonFullName: this.contactPersonFullName,
            startDate: this.startDate,
            hovedforfald: this.hovedforfald
        };
    }
    async isValid() {
        return await (0, class_validator_1.validate)(this).then(errors => {
            if (errors.length > 0) {
                return errors;
            }
            else {
                return null;
            }
        });
    }
}
__decorate([
    (0, class_validator_1.IsString)()
], CompanyInfo.prototype, "name", void 0);
__decorate([
    (0, class_validator_1.IsNumber)()
], CompanyInfo.prototype, "cvr", void 0);
__decorate([
    (0, class_validator_1.ValidateNested)({ each: true })
], CompanyInfo.prototype, "address", void 0);
__decorate([
    (0, class_validator_1.IsString)(),
    (0, class_validator_1.IsOptional)()
], CompanyInfo.prototype, "phone", void 0);
__decorate([
    (0, class_validator_1.IsString)(),
    (0, class_validator_1.IsOptional)()
], CompanyInfo.prototype, "contactPersonFullName", void 0);
__decorate([
    (0, class_validator_1.IsString)(),
    (0, class_validator_1.IsOptional)()
], CompanyInfo.prototype, "contactPersonEmail", void 0);
__decorate([
    (0, class_validator_1.IsDate)(),
    (0, class_validator_1.IsOptional)()
], CompanyInfo.prototype, "startDate", void 0);
__decorate([
    (0, class_validator_1.IsDate)(),
    (0, class_validator_1.IsOptional)()
], CompanyInfo.prototype, "hovedforfald", void 0);
exports.CompanyInfo = CompanyInfo;

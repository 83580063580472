import axios from 'axios'
import config from '../../../../../conf.json'
import { superModel } from '../../../../models/superModel'

const supplierVuex = {
	namespaced: true,
	state: {
		supplier: {},
		supplierLogs: [],
	},
	mutations: {
		setSupplier(state, data) {
			state.supplier = data
		},
		setSupplierLogs(state, data) {
			state.supplierLogs = data
		},
		unset(state) {
			state.supplier = {}
		},
	},
	actions: {
		clearState({ commit }) {
			commit('setSupplier', {})
			commit('setSupplierLogs', [])
		},
		async lookupCVR({ commit, rootGetters }, cvr) {
			console.log(cvr)
			var options = {
				url: 'https://cvrapi.dk/api?search=' + cvr + '&country=dk',
				method: 'GET',
			}
			var result = await axios(options).catch((error) => {
				console.log(error)
				throw error.response.data.error
			})
			console.log(result)
			return result.data
		},
		async getSupplier({ commit, rootGetters }, id) {
			let user = rootGetters['auth/user']
			let idToken = await rootGetters['auth/idToken']

			var options = {
				url: config.firebaseConfig.apiURL + '/supplier/' + id,
				method: 'GET',
				headers: {
					Authorization: 'Bearer ' + idToken,
				},
			}
			var result = await axios(options)
			var supplier = await superModel('supplier', result.data)
			commit('setSupplier', supplier)
		},
		async addSupplier({ rootGetters }, data) {
			let user = rootGetters['auth/user']
			let idToken = await rootGetters['auth/idToken']

			var options = {
				url: config.firebaseConfig.apiURL + '/supplier/',
				method: 'POST',
				headers: {
					Authorization: 'Bearer ' + idToken,
				},
				data: data,
			}
			var result = await axios(options).catch((error) => {
				console.log('ERROR WITH REQUEST')
				console.log(error.request)
				throw error.response.data
			})
			this.dispatch('supplier/reload')
			var supplier = await superModel('supplier', result.data)
			return supplier
		},
		async getSupplierLogs({ commit, rootGetters }, id) {
			let user = rootGetters['auth/user']
			let idToken = await rootGetters['auth/idToken']

			var options = {
				url: config.firebaseConfig.apiURL + '/supplier/' + id + '/logs',
				method: 'GET',
				headers: {
					Authorization: 'Bearer ' + idToken,
				},
			}
			var result = await axios(options)
			var arr = []
			for (const data of result.data) {
				try {
					var log = await superModel('log', data)
					arr.push(log)
				} catch (err) {
					console.log(err)
					console.log(data)
				}
			}
			commit('setSupplierLogs', arr)
		},
		async update({ rootGetters, dispatch }, { payload, id }) {
			let user = rootGetters['auth/user']
			let idToken = await rootGetters['auth/idToken']

			var options = {
				url: config.firebaseConfig.apiURL + '/supplier/' + id,
				method: 'PUT',
				headers: {
					Authorization: 'Bearer ' + idToken,
				},
				data: payload,
			}
			await axios(options).catch((error) => {
				throw new Error(error.response.data)
			})
			dispatch('supplierVuex/getSupplier', id, { root: true })
			dispatch('supplier/reload', null, { root: true })
		},
		async deleteSupplier({ rootGetters, dispatch, commit }, id) {
			let user = rootGetters['auth/user']
			let idToken = await rootGetters['auth/idToken']

			var options = {
				url: config.firebaseConfig.apiURL + '/supplier/' + id,
				method: 'DELETE',
				headers: {
					Authorization: 'Bearer ' + idToken,
				},
			}
			var result = await axios(options).catch((error) => {
				console.log(error)
				throw error.response.data
			})
			commit('supplierVuex/unset', null, { root: true })
			dispatch('supplier/reload', null, { root: true })
		},
	},
	getters: {
		isSet(state) {
			return state.suppliers.length > 0
		},
		supplierLogs(state) {
			return state.supplierLogs
		},
		supplier(state) {
			return state.supplier
		},
	},
}

export default supplierVuex

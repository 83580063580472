"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Company = void 0;
const class_validator_1 = require("class-validator");
const group_1 = require("./sub-models/company/group");
const companyInfo_1 = require("./sub-models/company/companyInfo");
const companyAdvisors_1 = require("./sub-models/general/companyAdvisors");
const companyInsurance_1 = require("./sub-models/company/companyInsurance");
const companyPension_1 = require("./sub-models/company/companyPension");
const kyc_1 = require("./sub-models/company/kyc");
const uuid_1 = require("uuid");
class Company {
    constructor(fields) {
        this.companyInfo = new companyInfo_1.CompanyInfo({});
        this.companyAdvisors = new companyAdvisors_1.CompanyAdvisors({});
        this.notificationSubscriptions = [];
        this.kycList = [];
        this.groups = [];
        this.insurances = [];
        this.pensions = [];
        this.companyAdmins = [];
        this.comment = '';
        this.commentEmployee = '';
        this.kycComment = '';
        this.employees = [];
        for (let [key, value] of Object.entries(fields)) {
            switch (key) {
                case 'groups':
                    this[key] = value.map((el) => {
                        return new group_1.Group(el);
                    });
                    break;
                case 'kycList':
                    this[key] = value.map((el) => {
                        return new kyc_1.Kyc(el);
                    });
                    break;
                case 'companyInfo':
                    this[key] = new companyInfo_1.CompanyInfo(value);
                    break;
                case 'notificationSubscriptions':
                    this[key] = value;
                    break;
                case 'companyAdvisors':
                    this[key] = new companyAdvisors_1.CompanyAdvisors(value);
                    break;
                case 'insurances':
                    this[key] = value.map((el) => {
                        return new companyInsurance_1.CompanyInsurance(el);
                    });
                    break;
                case 'pensions':
                    this[key] = value.map((el) => {
                        return new companyPension_1.CompanyPension(el);
                    });
                    break;
                case 'createdAt':
                    if (value) {
                        if (value.hasOwnProperty('_seconds')) {
                            //Parse from timestamp to date
                            this[key] = new Date(value.toDate());
                        }
                        else {
                            this[key] = new Date(value);
                        }
                    }
                    break;
                case 'updatedAt':
                    if (value) {
                        if (value.hasOwnProperty('_seconds')) {
                            //Parse from timestamp to date
                            this[key] = new Date(value.toDate());
                        }
                        else {
                            this[key] = new Date(value);
                        }
                    }
                    break;
                default:
                    this[key] = value;
                    break;
            }
        }
    }
    addGroup() {
        var uuid = (0, uuid_1.v4)();
        this.groups.push(new group_1.Group({ id: uuid }));
    }
    addInsurance(insurance) {
        var uuid = (0, uuid_1.v4)();
        this.insurances.push(new companyInsurance_1.CompanyInsurance({ insurance: insurance, id: uuid }));
    }
    addGroupToInsurance(groupId, index) {
        this.insurances[index].addGroup(groupId);
    }
    addPension(pension) {
        var uuid = (0, uuid_1.v4)();
        this.pensions.push(new companyPension_1.CompanyPension({ pension: pension, id: uuid }));
    }
    addGroupToPension(groupId, index) {
        this.pensions[index].addGroup(groupId);
    }
    removePension(index) {
        this.pensions.splice(index, 1);
    }
    removeInsurance(index) {
        this.insurances.splice(index, 1);
    }
    addKyc() {
        this.kycList.push(new kyc_1.Kyc({}));
    }
    removeKyc(index) {
        this.kycList.splice(index, 1);
    }
    toJSONObject() {
        return {
            companyInfo: this.companyInfo.toJSONObject(),
            companyAdvisors: this.companyAdvisors.toJSONObject(),
            groups: this.groups.map((item) => {
                return item.toJSONObject();
            }),
            companyAdmins: this.companyAdmins,
            comment: this.comment,
            commentEmployee: this.commentEmployee,
            id: this.id,
            createdAt: this.createdAt,
            updatedAt: this.updatedAt,
            employees: this.employees,
            insurances: this.insurances.map((item) => {
                return item.toJSONObject();
            }),
            pensions: this.pensions.map((item) => {
                return item.toJSONObject();
            }),
            notificationSubscriptions: this.notificationSubscriptions,
            kycList: this.kycList.map((item) => {
                return item.toJSONObject();
            }),
            kycComment: this.kycComment,
        };
    }
    toDatabase() {
        return {
            companyInfo: this.companyInfo.toDatabase(),
            companyAdvisors: this.companyAdvisors.toDatabase(),
            groups: this.groups.map((item) => {
                return item.toJSONObject();
            }),
            companyAdmins: this.companyAdmins,
            comment: this.comment,
            commentEmployee: this.commentEmployee,
            employees: this.employees,
            notificationSubscriptions: this.notificationSubscriptions,
            kycList: this.kycList.map((item) => {
                return item.toDatabase();
            }),
            kycComment: this.kycComment,
        };
    }
    async isValid() {
        return await (0, class_validator_1.validate)(this).then((errors) => {
            if (errors.length > 0) {
                return errors;
            }
            else {
                return null;
            }
        });
    }
}
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsString)()
], Company.prototype, "id", void 0);
__decorate([
    (0, class_validator_1.ValidateNested)({ each: true })
], Company.prototype, "companyInfo", void 0);
__decorate([
    (0, class_validator_1.ValidateNested)({ each: true })
], Company.prototype, "companyAdvisors", void 0);
__decorate([
    (0, class_validator_1.ValidateNested)({ each: true })
], Company.prototype, "notificationSubscriptions", void 0);
__decorate([
    (0, class_validator_1.ValidateNested)({ each: true })
], Company.prototype, "kycList", void 0);
__decorate([
    (0, class_validator_1.ValidateNested)({ each: true })
], Company.prototype, "groups", void 0);
__decorate([
    (0, class_validator_1.ValidateNested)({ each: true })
], Company.prototype, "insurances", void 0);
__decorate([
    (0, class_validator_1.ValidateNested)({ each: true })
], Company.prototype, "pensions", void 0);
__decorate([
    (0, class_validator_1.IsString)({ each: true })
], Company.prototype, "companyAdmins", void 0);
__decorate([
    (0, class_validator_1.IsString)()
], Company.prototype, "comment", void 0);
__decorate([
    (0, class_validator_1.IsString)()
], Company.prototype, "commentEmployee", void 0);
__decorate([
    (0, class_validator_1.IsString)()
], Company.prototype, "kycComment", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsDate)()
], Company.prototype, "createdAt", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsDate)()
], Company.prototype, "updatedAt", void 0);
__decorate([
    (0, class_validator_1.ValidateNested)({ each: true })
], Company.prototype, "employees", void 0);
exports.Company = Company;

import axios from 'axios'
import config from '../../../../../conf.json'
import { superModel } from '../../../../models/superModel'

const companyVuex = {
	namespaced: true,
	state: {
		company: {},
		companyLogs: [],
		employeePolicyLogs: [],
		employeePolices: [],
		employees: [],
		companyAdmins: [],
		companyAdvisors: [],
		isReady: false,
		employeesIsReady: false,
		employeePolicyIsReady: false,
	},
	mutations: {
		setEmployeePolicyIsReady(state, payload) {
			state.employeePolicyIsReady = payload
		},
		setEmployeesIsReady(state, payload) {
			state.employeesIsReady = payload
		},
		setCompany(state, data) {
			state.company = data
		},
		setCompanyLogs(state, data) {
			state.companyLogs = data
		},
		setEmployees(state, data) {
			state.employees = data
		},
		setInsurances(state, data) {
			state.company.insurances = data
		},
		setInsurance(state, { insurance, id }) {
			var index = state.company.insurances.findIndex((item) => item.id === id)
			state.company.insurances[index].insurance = insurance
		},
		setPension(state, { id, pension }) {
			var index = state.company.pensions.findIndex((item) => item.id === id)
			state.company.pensions[index].pension = pension
		},
		setPensions(state, data) {
			state.company.pensions = data
		},
		setCompanyAdmins(state, data) {
			state.companyAdmins = data
		},
		setCompanyAdvisors(state, data) {
			state.companyAdvisors = data
		},
		setEmployeePolicies(state, arr) {
			state.employeePolices = arr
		},
		setReady(state, bool) {
			state.isReady = bool
		},
		addEmployeePolicyLogs(state, data) {
			state.employeePolicyLogs = state.employeePolicyLogs.concat(data)
		},
		setEmployeePolicyLogs(state, data) {
			state.employeePolicyLogs = data
		},
	},
	actions: {
		async clearState({ commit }) {
			commit('setCompany', [])
			commit('setCompanyLogs', [])
			commit('setEmployees', [])
			commit('setInsurances', [])
			commit('setPensions', [])
			commit('setEmployeePolicies', [])
			commit('setCompanyAdmins', [])
			commit('setCompanyAdvisors', [])
			commit('setEmployeePolicyLogs', [])
			commit('setReady', false)
			commit('setEmployeesIsReady', false)
			commit('setEmployeePolicyIsReady', false)
			console.log('Company cleared')
		},
		async reloadCompany({ commit, dispatch }, companyId) {
			var promises = []
			promises.push(dispatch('companyVuex/getCompany', companyId, { root: true }))
			promises.push(dispatch('companyVuex/getEmployees', companyId, { root: true }))
			promises.push(dispatch('companyVuex/getEmployeePolicies', companyId, { root: true }))
			promises.push(dispatch('companyVuex/getCompanyAdmins', companyId, { root: true }))
			promises.push(dispatch('companyVuex/getCompanyAdvisors', companyId, { root: true }))
			Promise.all(promises)
			console.log('Company loaded')
			commit('setReady', true)
		},
		async getEmployeePolicyLogs({ commit, rootGetters }, id) {
			const employeePolicy = rootGetters['companyVuex/employeePolicies'].find((item) => item.id === id)
			var employeeId = employeePolicy.employeeId
			const idToken = await rootGetters['auth/idToken']
			const options = {
				url: config.firebaseConfig.apiURL + '/employeePolicy/' + id + '/logs',
				method: 'GET',
				headers: {
					Authorization: 'Bearer ' + idToken,
				},
			}
			const result = await axios(options).catch((error) => {
				throw error.response.data
			})
			const arr = []
			for (const data of result.data) {
				const log = await superModel('log', data)
				log.data.employeeId = employeeId
				arr.push(log.toJSONObject())
			}
			commit('addEmployeePolicyLogs', arr)
		},
		async getCompanyAdvisors({ rootGetters, commit }, companyId) {
			let idToken = await rootGetters['auth/idToken']

			var options = {
				url: config.firebaseConfig.apiURL + '/company/' + companyId + '/advisor',
				method: 'GET',
				headers: {
					Authorization: 'Bearer ' + idToken,
				},
			}
			var result = await axios(options)
			var arr = []
			for (const data of result.data) {
				var user = await superModel('user', data)
				arr.push(user)
			}
			console.log('Getting company advisors')

			commit('setCompanyAdvisors', arr)
		},
		async getCompanyAdmins({ rootGetters, commit }, companyId) {
			let idToken = await rootGetters['auth/idToken']

			var options = {
				url: config.firebaseConfig.apiURL + '/company/' + companyId + '/companyAdmin',
				method: 'GET',
				headers: {
					Authorization: 'Bearer ' + idToken,
				},
			}
			var result = await axios(options)
			var arr = []
			for (const data of result.data) {
				var user = await superModel('user', data)
				arr.push(user)
			}
			console.log('Getting company admins')

			commit('setCompanyAdmins', arr)
		},
		async getEmployees({ rootGetters, commit }, companyId) {
			let idToken = await rootGetters['auth/idToken']
			var options = {
				url: config.firebaseConfig.apiURL + '/company/' + companyId + '/employee',
				method: 'GET',
				headers: {
					Authorization: 'Bearer ' + idToken,
				},
			}
			var result = await axios(options)
			var arr = []
			for (const data of result.data) {
				var user = await superModel('user', data)
				arr.push(user)
			}
			console.log('Getting employees')

			commit('setEmployees', arr)
			commit('setEmployeesIsReady', true)
		},
		// For companyEmployeeList, we need to have a list of active employePolcicies for the users, so we can view salary of the user
		async getEmployeePolicies({ rootGetters, commit }, companyId) {
			let idToken = await rootGetters['auth/idToken']

			var options = {
				url: config.firebaseConfig.apiURL + '/employeePolicy/company/' + companyId,
				method: 'GET',
				headers: {
					Authorization: 'Bearer ' + idToken,
				},
			}
			var result = await axios(options)
			var arr = []
			for (const data of result.data) {
				var employeePolicy = await superModel('employeePolicy', data)
				arr.push(employeePolicy)
			}

			commit('setEmployeePolicies', arr)
			commit('setEmployeePolicyIsReady', true)
		},
		async getEmployeeByCpr({ commit, rootGetters }, cpr) {
			let idToken = await rootGetters['auth/idToken']
			console.log(cpr)
			var options = {
				url: config.firebaseConfig.apiURL + '/user/cpr/' + cpr,
				method: 'GET',
				headers: {
					Authorization: 'Bearer ' + idToken,
				},
			}
			console.log('Getting user')
			var result = await axios(options)
			console.log(result.data)
			var user = await superModel('user', result.data)
			return user
		},
		async lookupCVR({}, cvr) {
			var options = {
				url: 'https://cvrapi.dk/api?search=' + cvr + '&country=dk',
				method: 'GET',
			}
			return await axios(options)
				.then((result) => {
					return result.data
				})
				.catch((error) => {
					throw new Error(error.response.data.error)
				})
		},
		async getCompany({ commit, rootGetters }, id) {
			let idToken = await rootGetters['auth/idToken']

			var options = {
				url: config.firebaseConfig.apiURL + '/company/' + id,
				method: 'GET',
				headers: {
					Authorization: 'Bearer ' + idToken,
				},
			}
			var result = await axios(options)
			var company = await superModel('company', result.data)
			console.log('Getting company')

			commit('setCompany', company)
		},
		async getCompanyLogs({ commit, rootGetters }, id) {
			let idToken = await rootGetters['auth/idToken']
			var options = {
				url: config.firebaseConfig.apiURL + '/company/' + id + '/logs',
				method: 'GET',
				headers: {
					Authorization: 'Bearer ' + idToken,
				},
			}
			var result = await axios(options)
			var arr = []
			for (const data of result.data) {
				var log = await superModel('log', data)
				arr.push(log)
			}
			console.log('Getting company logs')

			commit('setCompanyLogs', arr)
		},
		async addCompany({ rootGetters, commit, dispatch }, data) {
			let idToken = await rootGetters['auth/idToken']

			var options = {
				url: config.firebaseConfig.apiURL + '/company',
				method: 'POST',
				headers: {
					Authorization: 'Bearer ' + idToken,
				},
				data: data,
			}
			var result = await axios(options).catch((error) => {
				console.log('ERROR')
				console.log(error.response.data)
				throw error.response.data
			})
			dispatch('company/reloadCompanies', null, { root: true })
			var company = await superModel('company', result.data)
			return company
		},
		async addCompanyInsurance({ rootGetters, commit, dispatch }, { companyId, data }) {
			let idToken = await rootGetters['auth/idToken']
			var options = {
				url: config.firebaseConfig.apiURL + '/company/' + companyId + '/insurance',
				method: 'POST',
				headers: {
					Authorization: 'Bearer ' + idToken,
				},
				data: data,
			}
			var result = await axios(options).catch((error) => {
				console.log('ERROR')
				console.log(error.response.data)
				throw error.response.data
			})
			dispatch('companyVuex/reloadCompany', companyId, { root: true })
			return result
		},
		async addCompanyPension({ rootGetters, commit, dispatch }, { companyId, data }) {
			let idToken = await rootGetters['auth/idToken']
			var options = {
				url: config.firebaseConfig.apiURL + '/company/' + companyId + '/pension',
				method: 'POST',
				headers: {
					Authorization: 'Bearer ' + idToken,
				},
				data: data,
			}
			var result = await axios(options).catch((error) => {
				console.log('ERROR')
				console.log(error.response.data)
				throw error.response.data
			})
			dispatch('companyVuex/reloadCompany', companyId, { root: true })
			return result
		},
		async updateCompany({ rootGetters, dispatch }, { data, id }) {
			let idToken = await rootGetters['auth/idToken']

			var options = {
				url: config.firebaseConfig.apiURL + '/company/' + id,
				method: 'PUT',
				headers: {
					Authorization: 'Bearer ' + idToken,
				},
				data: data,
			}
			var result = await axios(options).catch((error) => {
				console.log('ERROR')
				console.log(error.response.data)
				throw error.response.data
			})
			dispatch('companyVuex/reloadCompany', id, { root: true })
			return result
		},
		async updateCompanyInsurance({ rootGetters, dispatch }, { data, companyId, insuranceId }) {
			let idToken = await rootGetters['auth/idToken']

			var options = {
				url: config.firebaseConfig.apiURL + '/company/' + companyId + '/insurance/' + insuranceId,
				method: 'PUT',
				headers: {
					Authorization: 'Bearer ' + idToken,
				},
				data: data,
			}
			var result = await axios(options).catch((error) => {
				console.log('ERROR')
				console.log(error.response.data)
				throw error.response.data
			})
			dispatch('companyVuex/reloadCompany', companyId, { root: true })
			return result
		},
		async updateCompanyPension({ rootGetters, dispatch }, { data, companyId, pensionId }) {
			let idToken = await rootGetters['auth/idToken']

			var options = {
				url: config.firebaseConfig.apiURL + '/company/' + companyId + '/pension/' + pensionId,
				method: 'PUT',
				headers: {
					Authorization: 'Bearer ' + idToken,
				},
				data: data,
			}
			var result = await axios(options).catch((error) => {
				throw new Error(error.response.data)
			})
			dispatch('companyVuex/reloadCompany', companyId, { root: true })
			return result
		},
		async importEmployee({ rootGetters }, { data, id }) {
			let idToken = await rootGetters['auth/idToken']

			var options = {
				url: config.firebaseConfig.apiURL + '/company/' + id + '/employee/import',
				method: 'POST',
				headers: {
					Authorization: 'Bearer ' + idToken,
				},
				data: data,
			}
			var result = await axios(options).catch((error) => {
				console.log(error.response.data)
				throw new Error(error.response.data.message)
			})
			var employeeId = result.data
			return employeeId
		},
		async registerEmployee({ rootGetters, dispatch }, { data, id }) {
			let idToken = await rootGetters['auth/idToken']

			var options = {
				url: config.firebaseConfig.apiURL + '/company/' + id + '/employee',
				method: 'POST',
				headers: {
					Authorization: 'Bearer ' + idToken,
				},
				data: data,
			}
			var result = await axios(options).catch((error) => {
				console.log(error.response.data)
				throw new Error(error.response.data.message)
			})
			dispatch('companyVuex/reloadCompany', id, { root: true })
			dispatch('user/reload', null, { root: true })
			return result.data
		},
		async removeEmployee({ rootGetters, dispatch }, { companyId, employeeId }) {
			let idToken = await rootGetters['auth/idToken']

			var options = {
				url: config.firebaseConfig.apiURL + '/company/' + companyId + '/employee/' + employeeId,
				method: 'DELETE',
				headers: {
					Authorization: 'Bearer ' + idToken,
				},
			}
			var result = await axios(options).catch((error) => {
				console.log(error.response.data)
				throw error.response.data.message
			})
			dispatch('companyVuex/reloadCompany', companyId, { root: true })
			return result
		},
		async removeCompanyAdmin({ rootGetters, dispatch }, { companyId, uid }) {
			let idToken = await rootGetters['auth/idToken']

			var options = {
				url: config.firebaseConfig.apiURL + '/company/' + companyId + '/companyAdmin/' + uid,
				method: 'DELETE',
				headers: {
					Authorization: 'Bearer ' + idToken,
				},
			}
			var result = await axios(options).catch((error) => {
				console.log(error.response.data)
				throw new Error(error.response.data.message)
			})
			dispatch('companyVuex/reloadCompany', companyId, { root: true })
			dispatch('user/reload', null, { root: true })
			return result
		},
		async registerCompanyAdmin({ rootGetters, dispatch }, { data, id }) {
			let idToken = await rootGetters['auth/idToken']

			var options = {
				url: config.firebaseConfig.apiURL + '/company/' + id + '/companyAdmin',
				method: 'POST',
				headers: {
					Authorization: 'Bearer ' + idToken,
				},
				data: data,
			}
			var result = await axios(options).catch((error) => {
				console.log(error.response.data)
				throw new Error(error.response.data.message)
			})
			dispatch('companyVuex/reloadCompany', id, { root: true })
			dispatch('user/reload', null, { root: true })
			var userId = result.data
			return userId
		},
		async setEmployee({ rootGetters, dispatch }, { companyId, cpr }) {
			let idToken = await rootGetters['auth/idToken']

			var options = {
				url: config.firebaseConfig.apiURL + '/company/' + companyId + '/employee/' + cpr,
				method: 'PUT',
				headers: {
					Authorization: 'Bearer ' + idToken,
				},
			}
			var result = await axios(options).catch((error) => {
				console.log(error.response.data)
				throw new Error(error.response.data.message)
			})
			dispatch('companyVuex/reloadCompany', companyId, { root: true })
			dispatch('user/reload', null, { root: true })
			return result
		},
		async setCompanyAdmin({ rootGetters, dispatch }, { companyId, cpr }) {
			let idToken = await rootGetters['auth/idToken']

			var options = {
				url: config.firebaseConfig.apiURL + '/company/' + companyId + '/companyAdmin/' + cpr,
				method: 'PUT',
				headers: {
					Authorization: 'Bearer ' + idToken,
				},
			}
			var result = await axios(options).catch((error) => {
				console.log(error.response.data)
				throw new Error(error.response.data.message)
			})
			dispatch('companyVuex/reloadCompany', companyId, { root: true })
			dispatch('user/reload', null, { root: true })
			return result
		},
		async deleteInsurance({ rootGetters, dispatch }, { companyId, insuranceId }) {
			let idToken = await rootGetters['auth/idToken']

			var options = {
				url: config.firebaseConfig.apiURL + '/company/' + companyId + '/insurance/' + insuranceId,
				method: 'DELETE',
				headers: {
					Authorization: 'Bearer ' + idToken,
				},
			}
			var result = await axios(options).catch((error) => {
				console.log(error.response.data)
				throw error.response.data
			})
			dispatch('companyVuex/reloadCompany', companyId, { root: true })
		},
		async deletePension({ rootGetters, dispatch }, { companyId, pensionId }) {
			let idToken = await rootGetters['auth/idToken']

			var options = {
				url: config.firebaseConfig.apiURL + '/company/' + companyId + '/pension/' + pensionId,
				method: 'DELETE',
				headers: {
					Authorization: 'Bearer ' + idToken,
				},
			}
			var result = await axios(options).catch((error) => {
				console.log(error.response.data)
				throw error.response.data
			})
			dispatch('companyVuex/reloadCompany', companyId, { root: true })
		},
		async deleteCompany({ rootGetters, dispatch }, id) {
			let idToken = await rootGetters['auth/idToken']

			var options = {
				url: config.firebaseConfig.apiURL + '/company/' + id,
				method: 'DELETE',
				headers: {
					Authorization: 'Bearer ' + idToken,
				},
			}
			var result = await axios(options).catch((error) => {
				console.log(error.response.data)
				throw error.response.data
			})
			dispatch('company/reloadCompanies', null, { root: true })
		},
		async deleteGroup({ rootGetters, dispatch }, { companyId, groupId }) {
			let idToken = await rootGetters['auth/idToken']

			var options = {
				url: config.firebaseConfig.apiURL + '/company/' + companyId + '/group/' + groupId,
				method: 'DELETE',
				headers: {
					Authorization: 'Bearer ' + idToken,
				},
			}
			var result = await axios(options).catch((error) => {
				console.log(error.response.data)
				throw error.response.data
			})
		},
	},
	getters: {
		company(state) {
			return state.company
		},
		companyLogs(state) {
			return state.companyLogs
		},
		companyAdmins(state) {
			return state.companyAdmins
		},
		employees(state) {
			return state.employees
		},
		employeePolicies(state) {
			return state.employeePolices
		},
		insurances(state) {
			return state.company.insurances
		},
		insurance: (state) => (id) => {
			return state?.company?.insurances?.find((insurance) => insurance.id === id)
		},
		pension: (state) => (id) => {
			return state?.company?.pensions?.find((pension) => pension.id === id)
		},
		pensions(state) {
			return state.company.pensions
		},
		groups(state) {
			return state.company?.groups ?? []
		},
		isReady(state) {
			return state.isReady
		},
		employeeListIsReady(state) {
			return state.employeePolicyIsReady && state.employeesIsReady
		},
		employeePolicyLogs(state) {
			return state.employeePolicyLogs
		},
	},
}

export default companyVuex

const { AbilityBuilder, Ability } = require('@casl/ability')

module.exports = function defineAbilitiesFor(role, uid) {
	const { can, cannot, build } = new AbilityBuilder(Ability)
	var roleType = ''

	if (role.isCompanyAdmin) {
		let companies = role.companyAdminCompanies
		can(['read'], 'Company', { id: { $in: companies } })
		can(['read'], 'Companies')
		can(['read'], 'EmployeePolicy', ['EmployeeCondition', 'EmployeeInfo', 'EmployeePensions', 'CompanyAdvisors', 'Absence', 'SickLeave', 'companyAdminComment'])
		can(['read', 'create', 'update'], 'User')
		can(['read', 'update'], 'Auth', { uid: { $in: companies } })
		can(['read'], 'Supplier')
		can(['update'], 'EmployeePolicy', ['EmployeeCondition', 'Absence', 'SickLeave', 'Pension', 'companyAdminComment'], { companyId: { $in: companies } })
		can(['update'], 'User', ['FirstName', 'cpr', 'LastName'])
		cannot(['read'], 'Supplier', ['PensionPriceGroups', 'InsurancePriceGroups'])
		cannot(['update'], 'User', ['Role'])
		cannot(['read'], 'User', ['Auth', 'Agreements', 'InactiveAgreements', 'Logs', 'Api', 'Description'])
		cannot(['read'], 'Company', ['Logs', 'CompanyAdmins', 'Comment', 'CommentEmployee'])
		cannot(['read'], 'User', ['Logs'])
		cannot(['read', 'update'], 'EmployeePolicy', ['EmployeeCondition.salaryVisible'])
	}
	if (role.isEmployee) {
		let companies = role.employeeCompanies
		can(['read'], 'Company', ['CompanyInfo'], { id: { $in: companies } })
		can(['read'], 'EmployeePolicy', ['EmployeeInfo'], { employeeId: { $eq: uid } })
		can(['read'], 'CoverageOverview')
		can(['read', 'update'], 'User', { id: { $in: companies } })
		can(['read', 'update'], 'Auth', { uid: { $in: companies } })
		can(['read'], 'Supplier')
		can(['read'], 'EmployeePage')
		cannot(['read'], 'Supplier', ['PensionPriceGroups', 'InsurancePriceGroups'])
		cannot(['read'], 'User', ['Logs', 'Api', 'Description', 'InactiveAgreements'])
		cannot(['update'], 'User', ['Role'])
	}
	if (role.isAdvisor) {
		can(['create', 'read', 'update'], 'all') //Cannot delete
		cannot(['update'], 'User', ['Role'], { id: { $eq: uid } }) // Cannot update self
		cannot('read', 'EmployeePage')
		cannot('read', 'Report')
		
	}
	if (role.isAdmin) {
		can('manage', 'all')
		cannot('read', 'EmployeePage')
	} else {
		roleType = 'user'
		// Do nothing
	}

	return build()
}

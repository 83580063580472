"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Supplier = void 0;
const class_validator_1 = require("class-validator");
const contact_1 = require("./sub-models/supplier/contact");
const groupTemplate_1 = require("./sub-models/supplier/groupTemplate");
const uuid_1 = require("uuid");
class Supplier {
    constructor(fields) {
        this.name = '';
        this.excelName = '';
        this.website = '';
        this.contacts = [];
        this.groupTemplates = [];
        this.pensionPriceGroups = [];
        this.insurancePriceGroups = [];
        for (let [key, value] of Object.entries(fields)) {
            switch (key) {
                case 'contacts':
                    this[key] = value.map((el) => {
                        return new contact_1.Contact(el);
                    });
                    break;
                case 'groupTemplates':
                    this[key] = value.map((el) => {
                        return new groupTemplate_1.GroupTemplate(el);
                    });
                    break;
                case 'createdAt':
                    if (value) {
                        if (value.hasOwnProperty('_seconds')) {
                            //Parse from timestamp to date
                            this[key] = new Date(value.toDate());
                        }
                        else {
                            this[key] = new Date(value);
                        }
                    }
                    break;
                case 'updatedAt':
                    if (value) {
                        if (value.hasOwnProperty('_seconds')) {
                            //Parse from timestamp to date
                            this[key] = new Date(value.toDate());
                        }
                        else {
                            this[key] = new Date(value);
                        }
                    }
                    break;
                default:
                    this[key] = value;
                    break;
            }
        }
    }
    addGroupTemplate(name) {
        var uuid = (0, uuid_1.v4)();
        this.groupTemplates.push(new groupTemplate_1.GroupTemplate({ id: uuid, name: name }));
    }
    removeGroupTemplate(index) {
        this.groupTemplates.splice(index, 1);
    }
    toJSONObject() {
        return {
            name: this.name,
            cvr: this.cvr,
            website: this.website,
            id: this.id,
            contacts: this.contacts.map((item) => {
                return item.toJSONObject();
            }),
            pensionPriceGroups: this.pensionPriceGroups,
            insurancePriceGroups: this.insurancePriceGroups,
            groupTemplates: this.groupTemplates.map((item) => {
                return item.toJSONObject();
            }),
            excelName: this.excelName,
            createdAt: this.createdAt,
            updatedAt: this.updatedAt,
        };
    }
    toDatabase() {
        return {
            name: this.name,
            cvr: this.cvr,
            website: this.website,
            contacts: this.contacts.map((item) => {
                return item.toDatabase();
            }),
            groupTemplates: this.groupTemplates.map((item) => {
                return item.toDatabase();
            }),
            pensionPriceGroups: this.pensionPriceGroups,
            insurancePriceGroups: this.insurancePriceGroups,
            excelName: this.excelName,
        };
    }
    async isValid() {
        return await (0, class_validator_1.validate)(this).then((errors) => {
            if (errors.length > 0) {
                return errors;
            }
            else {
                return null;
            }
        });
    }
}
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsString)()
], Supplier.prototype, "id", void 0);
__decorate([
    (0, class_validator_1.IsString)()
], Supplier.prototype, "name", void 0);
__decorate([
    (0, class_validator_1.IsString)()
], Supplier.prototype, "excelName", void 0);
__decorate([
    (0, class_validator_1.IsNumber)()
], Supplier.prototype, "cvr", void 0);
__decorate([
    (0, class_validator_1.IsString)()
], Supplier.prototype, "website", void 0);
__decorate([
    (0, class_validator_1.ValidateNested)({ each: true })
], Supplier.prototype, "contacts", void 0);
__decorate([
    (0, class_validator_1.ValidateNested)({ each: true })
], Supplier.prototype, "groupTemplates", void 0);
__decorate([
    (0, class_validator_1.IsArray)()
], Supplier.prototype, "pensionPriceGroups", void 0);
__decorate([
    (0, class_validator_1.IsArray)()
], Supplier.prototype, "insurancePriceGroups", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsDate)()
], Supplier.prototype, "createdAt", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsDate)()
], Supplier.prototype, "updatedAt", void 0);
exports.Supplier = Supplier;
